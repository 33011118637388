<template>
    <v-btn-toggle v-model="query['issue.event.type']" color="primary" @change="emit">
        <template v-for="item in items">
            <v-btn
                v-bind="{
                    value: item.value,
                    height: 38,
                    color: 'white',
                }"
                :key="item.value"
            >
                <span> {{ item.text }} </span>
            </v-btn>
        </template>
    </v-btn-toggle>
</template>

<script>
import { ISSUE_EVENT_TYPES } from "@/assets/variables";

const items = Object.values(ISSUE_EVENT_TYPES).filter(({ value }) => value != ISSUE_EVENT_TYPES.USER_SPECIAL_DATE.value);

const issueEventType_default = ISSUE_EVENT_TYPES.PURCHASE_COMPLETE.value;

const initQuery = (query = {}) => ({
    ...(query || {}),

    ["issue.event.type"]: query?.["issue.event.type"] || issueEventType_default,
});

export default {
    data: () => ({
        items,

        query: initQuery(),
    }),

    methods: {
        sync() {
            this.query = initQuery(this.$route.query);
        },
        emit() {
            let { ...query } = this.query;

            delete query.page;

            if (!query?.["issue.event.type"] || query?.["issue.event.type"] == issueEventType_default) {
                delete query["issue.event.type"];
            }

            this.$router.push({ query });
        },
    },
    watch: {
        "$route.query"() {
            this.sync();
        },
    },
    mounted() {
        this.sync();
    },
};
</script>

<style></style>
