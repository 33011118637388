<template>
    <component :is="SkinComponent" v-model="form" v-bind="{ code, loading }" v-on="{ save }" />
</template>

<script>
import api from "@/api";
import axios from "axios";
import CryptoAES from "@/plugins/crypto-aes";
import { initUser, USER_TYPES } from "@/assets/variables";

export default {
    props: {
        skin: { type: String, default: null },
        code: { type: String, default: null },
    },

    data: () => ({
        form: initUser(),

        loading: false,
    }),

    computed: {
        SkinComponent() {
            return () => import(`./skin/${this.$props.skin}/input.vue`);
        },
        _certification() {
            return this.$route.query._certification;
        },
        type() {
            return this.$route.query.type;
        },
        accessToken() {
            return this.$route.query.accessToken;
        },
    },

    mounted() {
        if (!this.type || (this.type !== USER_TYPES.COMPANY.value && !this._certification)) {
            alert("잘못된 접근입니다.");
            this.$router.push("/");
        }
        this.init();
    },

    methods: {
        async init() {
            const IsUserType = this.type === USER_TYPES.PERSON.value;
            const certification = IsUserType ? await api.v1.cert.get({ _id: this._certification }) : { name: "", phone: "", birthday: "" };
            const _terms = this.$store.state.agreements._terms.map(({ _id }) => _id);
            const isReceiveAds = this.$store.state.agreements._terms.some(({ code }) => code === "adds");
            let { name, phone, birthday, ci } = certification;

            this.form = initUser({
                ...this.form,
                _terms,
                meta: { isReceiveAds, isReceiveEmail: false, isReceiveSMS: false },
                type: this.type,

                // USER
                _certification: IsUserType ? this._certification : undefined,
                name: name,
                ci: IsUserType ? CryptoAES.decrypt(ci) : undefined,
                phone: IsUserType ? phone.phoneNumberFormat() : undefined,
                birthday: IsUserType ? `${birthday.slice(0, 4)}-${birthday.slice(4, 6)}-${birthday.slice(6, 8)}` : undefined,
            });

            if (this.accessToken) {
                const { user } =
                    (
                        await axios({
                            url: "/api/v1/me/info",
                            method: "get",
                            headers: { Authorization: `Bearer ${this.accessToken}` },
                        })
                    )?.data || {};

                let { email, kakao, naver, apple } = user;
                this.form = initUser({
                    ...this.form,
                    email,
                    kakao,
                    naver,
                    apple,
                });
            }
        },

        async save() {
            if (this.loading) return;
            else this.loading = true;

            try {
                let { _businessRegistration, businessRegistration, ...form } = this.form;
                form.geolocation = await this.$getGeolocationWithKakaoMaps(form.address.address1);

                // 기업회원은 회원가입 시 닉네임 업체명으로 초기화
                let nickname = this.type === USER_TYPES.COMPANY.value ? (form?.companyName || "").replace(/(\s*)/g, "") : form.nickname || form?.username;

                const { user } = await api.v1.users.post({ ...form, nickname, password: CryptoAES.encrypt(form.password) });

                if (form.type === USER_TYPES.COMPANY.value) {
                    if (businessRegistration) {
                        let { file } = await api.v1.files.post({ userId: user._id, path: "businessRegistration" }, businessRegistration);

                        await api.v1.users.put({ _id: user._id, _businessRegistration: file._id });
                    }
                }
                if (this.accessToken) {
                    //temp user 삭제
                    await axios({
                        url: "/api/v1/users/deleteTempUser",
                        method: "delete",
                        headers: { Authorization: `Bearer ${this.accessToken}` },
                    });
                }

                this.$router.push(`/join/done?name=${this.form.name}&username=${this.form.username}`);
            } catch (error) {
                console.error(error);
                alert(error.response ? error.response.data.message : error.message);
            } finally {
                this.loading = false;
            }
        },
    },
};
</script>
