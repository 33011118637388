export const initDataTableHeaders = (headers = []) => {
    return headers.map((item, index, array) => ({
        ...item,

        formatter: initFormatter(item),

        align: item.align ?? "start",
        divider: item.divider ?? index != array.length - 1,

        class: (item.class || "") + " white-space-pre-line",
        cellClass: (item.cellClass || "") + " caption line-height-1-5 white-space-pre-line",
    }));
};

function initFormatter(header = {}) {
    if (header.formatter) return header.formatter;
    switch (header.formatterType) {
        case "date": {
            return (value) => value?.toDate?.() || value || "-";
        }
        case "datetime": {
            return (value) => value?.toDateTime?.() || value || "-";
        }
        case "number": {
            return (value) => ((value?.format?.() || value) + (header.suffix || "")).trim();
        }

        default: {
            return (value) => value ?? "-";
        }
    }
}
