<template>
    <v-row class="ma-n2">
        <v-col cols="12" md="6" class="pa-2">
            <v-select v-model="form.issue.event.type" label="발급시점" placeholder="없음" :items="eventTypes" v-bind="attrs_input__console" clearable @input="emit" />
        </v-col>
        <!-- <v-col cols="12" md="6" class="pa-2">
            <v-select v-model="form.issue.event.code" label="발급시점상세" :items="eventCodes__userSpecialDate" :disabled="eventCodeDisabled" v-bind="attrs_input__console" @input="emit" />
        </v-col> -->
    </v-row>
</template>

<script>
import { attrs_input__console, initShopCoupon } from "@/assets/variables";
import { ISSUE_EVENT_CODES__USER_SPECIAL_DATE, ISSUE_EVENT_TYPES } from "@/assets/variables/constants";

const eventTypes = Object.values(ISSUE_EVENT_TYPES).filter(({ value }) => value != ISSUE_EVENT_TYPES.USER_SPECIAL_DATE.value);
const eventCodes__userSpecialDate = Object.values(ISSUE_EVENT_CODES__USER_SPECIAL_DATE);

export default {
    props: {
        value: { type: Object, default: initShopCoupon },
    },
    data: () => ({
        form: initShopCoupon(),

        eventTypes,
        eventCodes__userSpecialDate,

        attrs_input__console,
    }),
    computed: {
        eventCodeDisabled() {
            return true;
            const eventCodeTypes = [ISSUE_EVENT_TYPES.USER_SPECIAL_DATE.value];
            return !eventCodeTypes.includes(this.form.issue.event.type);
        },
    },
    mounted() {
        this.sync();
    },
    watch: {
        value() {
            this.sync();
        },
    },
    methods: {
        sync() {
            this.form = initShopCoupon(this.value);
        },
        emit() {
            if (this.eventCodeDisabled && this.form.issue.event.code) {
                this.form.issue.event.code = null;
            }

            this.$emit("input", this.form);
        },
    },
};
</script>

<style></style>
