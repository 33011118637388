<template>
    <v-layout justify-center>
        <v-responsive width="100%" class="px-3 mx-n3">
            <list-heading title="주문 관리" showsFilterButton v-model="showsSearch" />

            <purchase-list-search v-bind="{ showsSearch }" />

            <v-row>
                <v-spacer />
                <v-col cols="auto">
                    <v-sheet outlined rounded>
                        <v-btn color="white" class="green--text px-2" v-bind="{ loading }" @click="excel"><v-icon class="mr-2">mdi-microsoft-excel</v-icon>엑셀다운로드</v-btn>
                    </v-sheet>
                </v-col>
            </v-row>

            <v-data-table
                v-bind="{
                    items,
                    headers,
                    loading,
                    itemsPerPage: -1,
                    itemClass: 'temp.rowClass',
                    disablePagination: true,
                    disableSort: true,
                    hideDefaultFooter: true,
                    dense: true,
                    class: 'v-sheet--outlined',
                }"
            >
                <template v-for="(header, headerIndex) in headers.filter((header) => header.hasOwnProperty('formatter'))" #[`item.${header.value}`]="{ value, item, index }"> <span :key="headerIndex" v-html="header.formatter.bind(item)(value, item, false, 0 < index ? items[index - 1] : null)" /> </template>
                <template #[`item.numbers`]="{ item }">
                    <div>
                        <v-tooltip bottom :disabled="!hasConsoleScope || !item.order?.isManualMoneyIssueRequired">
                            <template v-slot:activator="{ attrs, on }">
                                <v-sheet v-bind="attrs" v-on="on" color="transparent">
                                    <order-view :_order="item._order" v-on="{ search }">
                                        <template #activator="{ attrs, on }">
                                            <v-btn x-small text tile class="pa-0 caption" v-bind="attrs" :color="hasConsoleScope && item.order?.isManualMoneyIssueRequired ? 'red' : undefined" v-on="on">{{ item.order?.orderNo }}</v-btn>
                                        </template>
                                    </order-view>
                                </v-sheet>
                            </template>
                            예치금이 발급되지 않은 구매확정 주문입니다.
                        </v-tooltip>
                    </div>
                    <div>{{ item.purchaseNo }}</div>
                </template>
                <template #[`item.order.sender`]="{ item }">
                    <div :class="{ 'red--text': item.user ? item.user.isWarning : false }">{{ item.order?.sender?.name }}</div>
                    <div :class="{ 'red--text': item.user ? item.user.isWarning : false }">{{ item.order?.sender?.phone }}</div>
                    <div :class="{ 'red--text': item.user ? item.user.isWarning : false }">{{ item.order?.sender?.email }}</div>
                </template>
                <template #[`item.order.receiver`]="{ item }">
                    <div>{{ item.order?.receiver?.name }}</div>
                    <div>{{ item.order?.receiver?.phone }}</div>
                    <div>{{ item.order?.receiver?.email }}</div>
                </template>
                <template #[`item.order.receiver.address`]="{ item }">
                    <div>[{{ item.order?.receiver?.postcode }}]</div>
                    <div>{{ item.order?.receiver?.address1 }} {{ item.order?.receiver?.address2 }}</div>
                </template>
            </v-data-table>

            <v-pagination :value="page" :length="pageCount" :total-visible="11" color="primary" class="my-2" @input="(page) => $router.push({ query: { ...$route.query, page } })" />
        </v-responsive>
    </v-layout>
</template>

<script>
import api from "@/api";
import XLSX from "xlsx";
import { mapGetters } from "vuex";
import { initDataTableHeaders } from "@/assets/variables";
import { decode__productOptionName } from "@/plugins/vue-methods-shop";

import OrderView from "@/components/console/shop/purchases/order-view/order-view.vue";
import ListHeading from "@/components/console/dumb/list-heading.vue";
import PurchaseListSearch from "@/components/console/shop/purchases/purchase-list-search.vue";
import { paymentMethods } from "@/components/console/shop/purchases/order-view/order-view-payment.vue";

export default {
    components: {
        OrderView,
        ListHeading,
        PurchaseListSearch,
    },
    data: () => ({
        purchases: [],

        limit: 10,
        summary: { totalCount: 0 },

        loading: false,
        showsSearch: true,
    }),
    computed: {
        ...mapGetters(["getShippingCodeText", "hasConsoleScope"]),
        headers() {
            return initDataTableHeaders(
                [
                    {
                        width: 190,
                        text: "주문번호\r\n상품주문번호",
                        value: "numbers",
                        class: "white-space-pre-line",
                        excel: (value, item) => `${item.order?.orderNo || "-"}\r\n${item.purchaseNo || "-"}`,
                        splitOnExcel: "\r\n",
                    },
                    {
                        width: 100,
                        text: "주문일자",
                        value: "createdAt",
                        formatter: (value) => value?.toDateTime?.() || value || "-",
                    },
                    {
                        width: 110,
                        text: "구매확정상태",
                        value: "purchaseStatusMessage",
                    },
                    {
                        width: 110,
                        text: "구매확정일자",
                        value: "completedAt",
                        formatter: (value) => value?.toDateTime?.() || value || "-",
                    },
                    {
                        width: 100,
                        text: "주문상태",
                        value: "orderStatusMessage",
                    },
                    // {
                    //     width: 100,
                    //     text: "클레임상태",
                    //     value: "claimStatusMessage",
                    // },
                    // {
                    //     width: 100,
                    //     text: "클레임 사유",
                    //     value: "claimReason",
                    // },
                    // {
                    //     width: 200,
                    //     text: "클레임 상세사유",
                    //     value: "claimReasonDetails",
                    // },
                    {
                        width: 160,
                        text: "배송유형",
                        value: "shippingOptionCodeText",
                    },
                    {
                        width: 200,
                        text: "배송요청사항",
                        value: "order.requestMessage",
                    },
                    // {
                    //     width: 200,
                    //     text: "상품요청사항",
                    //     value: "requestMessage",
                    // },
                    {
                        width: 120,
                        text: "연락처",
                        value: "order.sender.phone",
                    },
                    // {
                    //     width: 120,
                    //     text: "상품코드",
                    //     value: "code",
                    // },
                    {
                        width: 200,
                        text: "구매상품",
                        value: "product.name",
                    },
                    {
                        width: 200,
                        text: "옵션정보",
                        value: "name",
                        formatter: decode__productOptionName,
                    },
                    {
                        width: +60,
                        text: "구매\r\n수량",
                        value: "amount",
                        align: "center",
                    },
                    {
                        width: 100,
                        text: "결제방법",
                        value: "order.paymentMethod",
                        align: "center",
                        formatter: (value) => paymentMethods[value]?.text || "-",
                    },
                    {
                        width: 110,
                        text: "판매금액\r\n실결제금액",
                        value: "prices",
                        align: "end",
                        formatter: (_, item) => {
                            let defaultPrice = (item.salePrice || 0) * (item.amount || 0);

                            let paymentPrice = defaultPrice;
                            paymentPrice += item.deliveryPrice || 0;
                            paymentPrice += item.servicePrice || 0;
                            paymentPrice += item.islandPrice || 0;
                            paymentPrice -= item.couponPrice || 0;
                            paymentPrice -= item.pointPrice || 0;

                            return `${defaultPrice.format()}원\r\n${paymentPrice.format()}원`;
                        },
                        excel: (_, item) => {
                            let defaultPrice = (item.salePrice || 0) * (item.amount || 0);

                            let paymentPrice = defaultPrice;
                            paymentPrice += item.deliveryPrice || 0;
                            paymentPrice += item.servicePrice || 0;
                            paymentPrice += item.islandPrice || 0;
                            paymentPrice -= item.couponPrice || 0;
                            paymentPrice -= item.pointPrice || 0;

                            return `${defaultPrice.format()}\r\n${paymentPrice.format()}`;
                        },
                        splitOnExcel: "\r\n",
                    },
                    {
                        width: +90,
                        text: "총 주문\r\n상품금액",
                        value: "purchasePrice__total",
                        formatterType: "number",
                        align: "end",
                        shows: this.hasConsoleScope,
                    },
                    {
                        width: +90,
                        text: "총 주문\r\n결제금액",
                        value: "order.totalPrice",
                        align: "end",
                        formatter: (value, item, isExcel, prev) => (item?._order == prev?._order ? "-" : value?.format?.()),
                        shows: this.hasConsoleScope,
                    },
                    {
                        width: +90,
                        text: "총 주문\r\n배송비 합계",
                        value: "order.deliveryPrice",
                        align: "end",
                        formatter: (value, item, isExcel, prev) => (item?._order == prev?._order ? "-" : ((value || 0) + (item?.order?.servicePrice || 0) + (item?.order?.islandPrice || 0))?.format?.()),
                        shows: this.hasConsoleScope,
                    },
                    {
                        width: 140,
                        text: "판매자",
                        value: "seller",
                        formatter: ({ nickname, username }) => `${nickname}\r\n${username}`,
                        splitOnExcel: (data) => {
                            const [nickname, username] = (data || "").split("\r\n");
                            return {
                                ["판매자 닉네임"]: nickname || "-",
                                ["판매자 아이디"]: username || "-",
                            };
                        },
                        shows: this.hasConsoleScope,
                    },
                    {
                        width: 140,
                        text: "구매자",
                        value: "order.sender",
                        excel: (value) => `${value?.name || "-"}\r\n${value?.phone || "-"}\r\n${value?.email || "-"}`,
                        splitOnExcel: (data) => {
                            const [name, phone, email] = (data || "").split("\r\n");
                            return {
                                ["구매자 이름"]: name || "-",
                                ["구매자 연락처"]: phone || "-",
                                ["구매자 이메일"]: email || "-",
                            };
                        },
                    },
                    {
                        width: 140,
                        text: "수취인",
                        value: "order.receiver",
                        excel: (value) => `${value?.name || "-"}\r\n${value?.phone || "-"}\r\n${value?.email || "-"}`,
                        splitOnExcel: (data) => {
                            const [name, phone, email] = (data || "").split("\r\n");
                            return {
                                ["수취인 이름"]: name || "-",
                                ["수취인 연락처"]: phone || "-",
                                ["수취인 이메일"]: email || "-",
                            };
                        },
                    },
                    {
                        width: 300,
                        text: "배송지",
                        value: "order.receiver.address",
                        excel: (value, item) => `[${item?.order?.receiver?.postcode || "-"}]\r\n${item?.order?.receiver?.address1 || "-"} ${item?.order?.receiver?.address2 || "-"}`,
                        splitOnExcel: (data, item) => {
                            return {
                                ["배송지 우편번호"]: item?.order?.receiver?.postcode || "-",
                                ["배송지 기본주소"]: item?.order?.receiver?.address1 || "-",
                                ["배송지 상세주소"]: item?.order?.receiver?.address2 || "-",
                            };
                        },
                    },
                ].filter(({ shows }) => shows ?? true)
            ).map((item) => ({
                ...item,
                class: (item.class || "") + " px-2",
                cellClass: (item.cellClass || "") + " px-2 py-1 line-height-1-3",
            }));
        },
        items() {
            const colors = ["table-row-grey", null];

            return [...this.purchases].reduce((items, item) => {
                let prev = items.at(-1);
                let isPrevSameOrder = prev?._order == item._order;

                let rowClass = prev?.temp?.rowClass;

                if (!isPrevSameOrder) {
                    let prevColorIndex = colors.indexOf(prev?.temp?.rowClass || null);
                    rowClass = colors[(prevColorIndex + 1) % 2];
                }

                items.push({ ...item, temp: { rowClass } });

                return items;
            }, []);
        },
        page() {
            return +(this.$route.query.page || "1");
        },
        skip() {
            return (this.page - 1) * this.limit;
        },
        pageCount() {
            return Math.ceil(this.summary.totalCount / this.limit) || 1;
        },
        params() {
            let { ...query } = this.$route.query;
            return { ...query };
        },
    },
    mounted() {
        this.init();
    },
    watch: {
        params() {
            this.search();
        },
    },
    methods: {
        async init() {
            try {
                await this.search(false);
            } catch (error) {
                console.error(error);
            }
        },

        async search() {
            if (this.loading) return;
            else this.loading = true;

            try {
                let { skip, limit, params } = this;
                var { summary, purchases } = await api.console.shop.purchases.getPurchases({
                    headers: { skip, limit },
                    params,
                });

                this.summary = summary;
                this.purchases = purchases;
            } finally {
                this.loading = false;
            }
        },

        async excel() {
            if (this.loading) return;
            else this.loading = true;

            try {
                let { params } = this;
                var { purchases } = await api.console.shop.purchases.getPurchases({ params });

                let fileName = `주문관리`;
                this.$excel(purchases, this.headers, fileName);
            } finally {
                this.loading = false;
            }
        },
    },
};
</script>

<style lang="scss" scoped>
::v-deep {
    .white-space-pre-line {
        white-space: pre-line;
    }
    .v-pagination button {
        box-shadow: none !important;
        border: thin solid rgba(0, 0, 0, 0.12);
    }
    tr.table-row-grey {
        background-color: rgba(68, 81, 99, 0.06);
    }
}
</style>
