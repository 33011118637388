<template>
    <div class="mb-54px mb-md-64px mb-lg-80px">
        <v-row class="row--sm">
            <v-col cols="12" lg="">
                <div class="font-size-18 font-size-md-22 font-size-lg-32 font-weight-light">
                    <b class="font-weight-bold">{{ user?.name }}</b
                    >님은<br />{{ levelName }}등급입니다.
                </div>
            </v-col>
            <v-col cols="12" lg="auto">
                <v-row no-gutters>
                    <v-col cols="auto" class="ml-n1px d-md-none"><v-divider vertical /></v-col>
                    <v-col cols="3" lg="">
                        <div class="py-8px" :style="$vuetify.breakpoint.lgAndUp ? 'min-width: 144px' : ''">
                            <div class="text-center">
                                <div class="txt txt--sm">마일리지</div>
                                <div class="font-size-18 font-size-lg-24 primary--text mt-18px mt-md-20px">{{ user?.point?.format?.() }}M</div>
                            </div>
                        </div>
                    </v-col>
                    <v-col cols="auto" class="ml-n1px"><v-divider vertical /></v-col>
                    <v-col cols="3" lg="">
                        <div class="py-8px" :style="$vuetify.breakpoint.lgAndUp ? 'min-width: 144px' : ''">
                            <div class="text-center">
                                <div class="txt txt--sm">쿠폰</div>
                                <!-- <div class="font-size-18 font-size-lg-24 primary--text mt-18px mt-md-20px">{{ user?.meta?.couponsCount?.format?.() }}장</div> -->
                                <div class="font-size-18 font-size-lg-24 primary--text mt-18px mt-md-20px">{{ (couponsSummary.totalCount || 0)?.format?.() }} 장</div>
                            </div>
                        </div>
                    </v-col>
                    <v-col cols="auto" class="ml-n1px"><v-divider vertical /></v-col>
                    <v-col cols="3" lg="">
                        <div class="py-8px" :style="$vuetify.breakpoint.lgAndUp ? 'min-width: 144px' : ''">
                            <div class="text-center">
                                <div class="txt txt--sm">관심상품</div>
                                <div class="font-size-18 font-size-lg-24 primary--text mt-18px mt-md-20px">{{ user?.meta?.wishlistCount?.format?.() }}개</div>
                            </div>
                        </div>
                    </v-col>
                    <!-- <v-col cols="auto" class="ml-n1px"><v-divider vertical /></v-col>
                    <v-col cols="3" lg="">
                        <div class="py-8px" :style="$vuetify.breakpoint.lgAndUp ? 'min-width: 144px' : ''">
                            <div class="text-center">
                                <div class="txt txt--sm">최근 본 상품</div>
                                <div class="font-size-18 font-size-lg-24 primary--text mt-18px mt-md-20px">5개</div>
                            </div>
                        </div>
                    </v-col> -->
                    <v-col cols="auto" class="ml-n1px d-md-none"><v-divider vertical /></v-col>
                </v-row>
            </v-col>
        </v-row>
    </div>
    <!-- <v-card tile color="primary" class="white--text">
        <div class="pa-18px px-md-10px pa-lg-24px">
            <div class="pb-18px pb-md-24px text-center">
                <div class="font-size-18 font-size-md-22 font-weight-bold line-height-1">{{ user?.name }} 님</div> -->
    <!-- <div class="font-size-14 line-height-1 mt-8px">{{ user?.username }}</div> -->
    <!-- </div>
            <v-divider style="border-color: rgba(255, 255, 255, 0.15) !important; background-color: transparent !important" />
            <page-section class="page-section--xs pb-0">
                <v-row v-for="({ term, data, type }, index) in items" :key="term" align="center" justify="space-between" class="row--sm font-size-14" :class="{ 'mt-8px': index != 0 }">
                    <v-col cols="auto">{{ term }}</v-col>
                    <v-col cols="auto" v-if="type == 'chip'">
                        <v-chip x-small outlined color="white">{{ data }}</v-chip>
                    </v-col>
                    <v-col cols="auto" v-else>{{ data }}</v-col>
                </v-row>
            </page-section>

            <page-section class="page-section--xs pb-0">
                <template v-if="isDashboard">
                    <v-btn block color="white" to="/mypage" class="grey--text text--darken-4"> 일반회원 페이지로 이동 </v-btn>
                </template>
            </page-section>
        </div>
    </v-card> -->
</template>

<script>
import { mapActions } from "vuex";

import PageSection from "@/components/client/templates/page-section.vue";
import api from "@/api";

export default {
    components: {
        PageSection,
    },
    props: {
        isDashboard: { type: Boolean, default: false },
    },
    data: () => ({
        couponsSummary: {
            totalCount: 0,
        },

        loading: false,
    }),
    computed: {
        user() {
            return this.$store.state.user;
        },
        items() {
            const items = [];

            items.push({ term: "회원등급", data: this.user?.level?.name });
            items.push({ term: "적립금", data: `${this.user?.point?.format?.() || 0}원`, type: "chip" });

            return items;
        },
        levelName() {
            if(!this.user?.level) return "일반";
            if(!this.user.level.isActive) return "일반";
            return this.user.level.name;
        }
    },
    mounted() {
        this.init();
    },
    methods: {
        ...mapActions(["getUser"]),
        async init() {
            if (this.loading) return;
            else this.loading = true;
            try {
                await this.getUser();
                const { summary } = await api.v1.me.coupons.gets({
                    headers: { mode: "summary" },
                    params: {
                        isUsed: JSON.stringify({ $ne: true }),
                        isExpired: JSON.stringify({ $ne: true }),
                        isDeleted: JSON.stringify({ $ne: true }),
                        ["usage.isActive"]: JSON.stringify({ $ne: false }),
                    },
                });
                this.couponsSummary = summary;
            } finally {
                this.loading = false;
            }
        },
    },
};
</script>

<style></style>
