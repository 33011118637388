<template>
    <!-- <u-dialog v-model="shows" title="쿠폰 사용하기" width="1080" maxWidth="1080" eager v-bind="{ loading }"> -->
    <u-dialog v-model="shows" title="사용 가능 쿠폰" width="800" maxWidth="800" eager v-bind="{ loading }">
        <template #activator="{ attrs, on }">
            <slot name="activator" v-bind="{ attrs: { ...attrs, loading, disabled }, isActive, on, selected }" />
        </template>

        <v-btn v-bind="{ ...btn_tertiary, loading }" width="100%" @click="cancel">선택안함</v-btn>
        <form-table v-model="selected" v-bind="{ items, order, loading }" />

        <!-- <v-row class="mx-n3 my-3">
            <v-col cols="8" class="pa-3">
                <v-divider class="grey darken-4" style="border-width: 2px 0 0 !important" />
                <form-table v-model="selected" v-bind="{ items, order, loading }" />
            </v-col>
            <v-col cols="4" class="pa-3">
                <cart-item-coupons-shipping-prices v-bind="{ order, carts, selected }" :coupons="value.coupons" />
            </v-col>
        </v-row> -->

        <template #actions>
            <v-row class="row--sm">
                <v-col>
                    <v-btn v-bind="btn_tertiary" block class="v-size--xx-large" @click="shows = false">취소</v-btn>
                </v-col>
                <v-col>
                    <v-btn v-bind="btn_primary" block class="v-size--xx-large" @click="save">저장하기</v-btn>
                </v-col>
            </v-row>
        </template>
    </u-dialog>
</template>

<script>
import api from "@/api";

import { mapGetters, mapState } from "vuex";
import { btn_primary, btn_tertiary, initOrder, USAGE_TARGET_PRICES } from "@/assets/variables";

import UDialog from "@/components/client/dumb/u-dialog.vue";
import FormTable from "@/components/client/shop/coupons/form/form-table.vue";
import CartItemCouponsShippingPrices from "./cart-item-coupons-shipping-prices.vue";

export default {
    components: {
        UDialog,
        FormTable,
        CartItemCouponsShippingPrices,
    },
    props: {
        value: { type: Array, default: () => [] }, // coupons
        order: { type: Object, default: initOrder },
        carts: { type: Array, default: () => [] },
        _seller: { type: String, default: null },
        _product: { type: String, default: null },
        shippingBundleType: { type: String, default: null },
        shippingMethodCode: { type: String, default: null },
        shippingRegionCode: { type: String, default: null },
    },
    data: () => ({
        coupons: [],
        selected: [],

        btn_primary,
        btn_tertiary,

        shows: false,
        loading: false,
    }),
    computed: {
        ...mapGetters(["userLevel"]),
        ...mapState(["accessToken"]),
        items() {
            const { shippingCode } = this;
            return [...(this.coupons || [])].map(this.makeItem);
        },
        isActive() {
            return !!this.selected.length;
        },
        disabled() {
            return !this.loading && !this.items?.length;
        },
    },
    mounted() {
        this.init();
    },
    watch: {
        value() {
            this.sync();
        },
        accessToken() {
            this.init();
        },
        shows() {
            this.emit();
        },
    },
    methods: {
        sync() {
            this.selected = this.value.map(this.makeItem);
        },

        emit() {
            this.$emit("input", this.selected);
        },

        makeItem(coupon) {
            const { shippingBundleType, shippingMethodCode, shippingRegionCode } = this;
            const selectable = !coupon.isUsed && !coupon.isExpired && !coupon.isDeleted;
            coupon = { ...coupon, _product: this._product, shippingBundleType, shippingMethodCode, shippingRegionCode, selectable };

            return { ...coupon, couponPrice: this.$getCouponPrice(this.carts, [coupon], this.order?.receiver?.geolocation, this.userLevel) };
        },

        async init() {
            if (!this.accessToken) {
                this.coupons = [];
                return;
            }

            if (this.loading) return;
            else this.loading = true;

            try {
                const { coupons: shopCoupons } = await api.v1.shop.coupons.gets({ params: { _product: this._product } });

                const { coupons } = await api.v1.me.coupons.gets({
                    params: {
                        _seller: this._seller,
                        _coupon: shopCoupons.map(({ _id }) => _id),
                        ["usage.target.price"]: USAGE_TARGET_PRICES.SHOP_DELIVERY_FARE.value,
                        isUsed: false,
                        isExpired: false,
                        isDeleted: false,
                        isDisabled: false,
                        ["usage.isActive"]: JSON.stringify({ $ne: false }),
                    },
                });
                this.coupons = coupons;

                // this.selected = coupons.filter((coupon) => !["_seller", "_product", "shippingBundleType", "shippingMethodCode", "shippingRegionCode"].some((key) => coupon[key] != this[key])).map(this.makeItem);
                this.selected = coupons
                    .filter((coupon) => {
                        return !["_seller", "_product", "shippingBundleType", "shippingMethodCode", "shippingRegionCode"].some((key) => {
                            if (key === "_seller") {
                                return this[key] !== (coupon[key] || coupon["_appliedSeller"]);
                            } else {
                                return coupon[key] !== this[key];
                            }
                        });
                    })
                    .map(this.makeItem);
            } finally {
                this.loading = false;
                this.emit();
            }
        },

        async validate() {
            const uncombinableCoupons__this = this.selected.filter((coupon) => !coupon?.usage?.combinable);
            //이미 중복불가 쿠폰 포함 시,
            const uncombinableCoupons__value = this.order.coupons.filter((coupon) => !coupon?.usage?.combinable);

            if (0 < uncombinableCoupons__this.length || 0 < uncombinableCoupons__value.length) {
                if (1 < this.selected.length) {
                    alert("중복사용 불가 쿠폰은 단독으로만 사용할 수 있습니다.");
                    this.loading = false;
                    return false;
                }

                const otherCoupons__order = this.order.coupons.filter((coupon) => coupon?.usage?.target?.price != USAGE_TARGET_PRICES.SHOP_DELIVERY_FARE.value || coupon?._product !== this._product);

                if (0 < otherCoupons__order.length) {
                    const go = confirm("이미 사용중인 다른 쿠폰이 있습니다.\r\n지금 선택하신 쿠폰으로 변경하시겠습니까?");

                    if (!go) {
                        alert("취소하셨습니다.");
                        this.loading = false;
                        return false;
                    }

                    for (const coupon of otherCoupons__order) {
                        const { _id } = coupon;
                        switch (coupon?.usage?.target?.price) {
                            case USAGE_TARGET_PRICES.SHOP_DELIVERY_FARE.value: {
                                await api.v1.me.coupons.putShipping({
                                    _id,
                                    _product: null,
                                    _appliedSeller: null,
                                    shippingBundleType: null,
                                    shippingMethodCode: null,
                                    shippingRegionCode: null,
                                });
                                break;
                            }
                            case USAGE_TARGET_PRICES.SHOP_PRODUCT_PRICE.value: {
                                await api.v1.me.coupons.putProduct({
                                    _id,
                                    _product: null,
                                });
                                break;
                            }
                            case USAGE_TARGET_PRICES.TOTAL_ORDER_AMOUNT.value: {
                                this.$emit("pullCoupon", coupon);
                                break;
                            }
                        }
                    }
                }
            }

            return true;
        },

        async save() {
            if (this.loading) return;
            else this.loading = true;
            const selected = [...this.selected];

            if (!(await this.validate())) return;

            try {
                const {
                    _product,

                    shippingBundleType,
                    shippingMethodCode,
                    shippingRegionCode,
                } = this;
                // const _selected = this.selected.map((coupon) => coupon?._id);
                // for (const { _id } of this.coupons.filter((coupon) => !_selected.includes(coupon._id) && !["_seller", "_product", "shippingBundleType", "shippingMethodCode", "shippingRegionCode"].some((key) => coupon[key] != this[key]))) {
                const _selected = selected.map((coupon) => coupon?._id);
                for (const { _id } of this.coupons.filter(
                    (coupon) =>
                        !_selected.includes(coupon._id) &&
                        !["_seller", "_product", "shippingBundleType", "shippingMethodCode", "shippingRegionCode"].some((key) => {
                            if (key === "_seller") {
                                return this[key] !== (coupon[key] || coupon["_appliedSeller"]);
                            } else {
                                return coupon[key] !== this[key];
                            }
                        })
                )) {
                    await api.v1.me.coupons.putShipping({
                        _id,
                        _product: null,
                        _appliedSeller: null,
                        shippingBundleType: null,
                        shippingMethodCode: null,
                        shippingRegionCode: null,
                    });
                }
                for (const _id of _selected) {
                    await api.v1.me.coupons.putShipping({
                        _id,
                        _product,
                        _appliedSeller: this._seller,
                        shippingBundleType,
                        shippingMethodCode,
                        shippingRegionCode,
                    });
                }
                this.loading = false;
                this.shows = false;
            } finally {
                if (this.loading) this.loading = false;
                this.emit();
            }
        },

        async cancel() {
            this.selected = [];
            this.save();
        },
    },
};
</script>

<style></style>
