<template>
    <v-data-table v-bind="{ items, headers }" mobile-breakpoint="10000" disable-pagination disable-sort hide-default-footer class="v-sheet--outlined fill-height" style="position: relative">
        <template #top> <div class="table-label px-1 white">결제정보</div> </template>
        <template v-for="(header, index) in headers.filter((header) => header.hasOwnProperty('formatter'))" #[`item.${header.value}`]="{ value, item }"> <span :key="index" v-html="header.formatter.bind(item)(value)" /> </template>
    </v-data-table>
</template>

<script>
import { mapGetters } from "vuex";

import { initOrder } from "@/assets/variables/inits";
import { PURCHASE_ORDER_STATUSES } from "@/assets/variables/constants";

export const paymentMethods = {
    card: { value: "card", text: "신용카드" },
    kakaopay: { value: "kakaopay", text: "카카오페이" },
    naverpayCard: { value: "naverpayCard", text: "네이버페이" },
    samsungpayCard: { value: "samsungpayCard", text: "삼성페이" },
    vbank: { value: "vbank", text: "가상계좌" },
    account: { value: "account", text: "무통장입금" },
};

export default {
    props: {
        order: { type: Object, default: initOrder },
    },
    computed: {
        ...mapGetters(["hasCompanyScope"]),
        items() {
            const { ...order } = this.order;
            if (this.hasCompanyScope) {
                order.pointPrice = order.purchases.reduce((sum, { pointPrice }) => sum + pointPrice, 0);

                order.productPrice = order.purchases.reduce((sum, { salePrice, discountPrice, amount }) => sum + (salePrice + discountPrice) * amount, 0);

                order.canceledPrice = order.purchases.reduce((sum, { orderStatus, salePrice, discountPrice, amount }) => sum + (orderStatus == PURCHASE_ORDER_STATUSES.ORDER_CANCELED.value ? (salePrice + discountPrice) * amount : 0), 0);

                order.discountPrice = order.purchases.reduce((sum, { discountPrice, amount }) => sum + discountPrice * amount, 0);

                order.levelDiscountPrice = order.purchases.reduce((sum, { levelDiscountPrice, amount }) => sum + levelDiscountPrice * amount, 0);

                order.canceledDiscountPrice = order.purchases.reduce((sum, { orderStatus, levelDiscountPrice, amount }) => sum + (orderStatus == PURCHASE_ORDER_STATUSES.ORDER_CANCELED.value ? levelDiscountPrice * amount : 0), 0);

                order.couponPrice = order.purchases.reduce((sum, { couponPrice }) => sum + couponPrice, 0);

                order.totalPrice = order.productPrice - order.discountPrice - order.levelDiscountPrice - order.couponPrice - order.pointPrice + order.deliveryPrice + order.servicePrice + order.islandPrice - order.canceledPrice + order.canceledDiscountPrice;
            }
            return [order];
        },
        headers() {
            const headers = [];

            if (!this.hasCompanyScope) headers.push({ text: "결제방법", value: "paymentMethod", formatter: (value) => paymentMethods[value]?.text || "-" });

            headers.push({ text: "결제금액", value: "totalPrice", formatter: (value) => `${value?.format?.() || value || 0}원` });

            if (this.order?.pointPrice) headers.push({ text: "사용적립금", value: "pointPrice", formatter: (value) => `${value?.format?.() || value || 0}원` });

            headers.push({ text: "상품금액", value: "productPrice", formatter: (value) => `${value?.format?.() || value || 0}원` });

            if (this.order?.canceledPrice) headers.push({ text: "취소상품금액", value: "canceledPrice", formatter: (value) => `${value?.format?.() || value || 0}원` });

            if (this.order?.discountPrice) headers.push({ text: "상품할인", value: "discountPrice", formatter: (value) => `${value?.format?.() || value || 0}원` });

            if (this.order?.levelDiscountPrice) headers.push({ text: "등급할인", value: "levelDiscountPrice", formatter: (value) => `${value?.format?.() || value || 0}원` });

            if (this.order?.canceledDiscountPrice) headers.push({ text: "취소상품할인", value: "canceledDiscountPrice", formatter: (value) => `${value?.format?.() || value || 0}원` });

            if (this.order?.couponPrice) headers.push({ text: "쿠폰할인", value: "couponPrice", formatter: (value) => `${value?.format?.() || value || 0}원` });

            headers.push({ text: "배송비", value: "deliveryPrice", formatter: (value) => `${value?.format?.() || value || 0}원` });

            if (this.items[0].servicePrice) {
                headers.push({ text: "설치비", value: "servicePrice", formatter: (value) => `${value?.format?.() || value || 0}원` });
            }

            if (this.order?.islandPrice) headers.push({ text: "추가배송비(도서산간)", value: "islandPrice", formatter: (value) => `${value?.format?.() || value || 0}원` });

            return headers.map((item, index, array) => ({ ...item, formatter: item.formatter ?? ((value) => value ?? "-"), cellClass: "caption", divider: index != array.length - 1 }));
        },
    },
};
</script>

<style lang="scss" scoped>
.table-label {
    position: absolute;
    left: 0;
    bottom: 100%;
    transform: translateY(50%) scale(0.75);

    color: rgba(0, 0, 0, 0.6);
    font-size: 16px;
}
::v-deep {
    .v-data-table__mobile-row {
        padding: 4px 16px !important;
        &,
        & * {
            height: auto !important;
            min-height: auto;
            font-size: 12px;
            font-weight: normal;
        }
        &:first-child {
            margin-top: 12px;
        }
        &:last-child {
            margin-bottom: 12px;
        }
    }
}
</style>
