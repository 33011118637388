<template>
    <client-page>
        <page-section titAlign="text-center" class="page-section--first page-section--last">
            <template slot="tit">
                <v-row justify="center" align="center" class="row--xs">
                    <v-col cols="auto" class="font-weight-light">본인인증</v-col>
                    <v-col cols="auto">
                        <v-card width="26" height="26" color="grey" outlined class="d-flex justify-center align-center">
                            <v-icon small class="grey--text">mdi-chevron-right</v-icon>
                        </v-card>
                    </v-col>
                    <v-col cols="auto" class="font-weight-light">정보입력</v-col>
                    <v-col cols="auto">
                        <v-card width="26" height="26" color="grey" outlined class="d-flex justify-center align-center">
                            <v-icon small class="grey--text">mdi-chevron-right</v-icon>
                        </v-card>
                    </v-col>
                    <v-col cols="auto" class="font-weight-light">가입완료</v-col>
                </v-row>
            </template>
            <h3 class="tit tit--xxs text-center">가입을 위한 본인인증을 진행해주세요.</h3>
            <div class="btn-wrap btn-wrap--lg" align="center">
                <v-btn class="v-size--xx-large v-btn--width-fixed" v-bind="{ ...btn_secondary }" @click="certify">휴대폰 인증</v-btn>
            </div>
            <v-sheet max-width="700" class="mx-auto mt-40px">
                <div class="txt">
                    <p class="dot">인증완료 시 이름/휴대폰번호는 인증된 정보로 갱신되며, 생일 및 통신사 정보가 추가로 수집됩니다.</p>
                    <p class="dot">
                        실명확인이 되지 않는 경우 아래의 실명인증기관에 실명등록을 요청할 수 있습니다.<br />
                        한국신용정보(주) 1588-2486
                    </p>
                    <p class="dot">인증완료 시 이름/휴대폰번호는 인증된 정보로 갱신되며, 생일 및 통신사 정보가 추가로 수집됩니다.</p>
                    <p class="dot">타인의 정보 및 주민등록번호를 부정하게 사용하는 경우 3년 이하의 징역 또는 1천만원 이하의 벌금에 처해질 수 있습니다. (주민등록법 제 37조)</p>
                </div>
            </v-sheet>
        </page-section>

        <kcp-cert ref="kcpCert" @input="verify" />
    </client-page>
</template>

<script>
import api from "@/api";
import cryptoAes from "@/plugins/crypto-aes";
import axios from "@/plugins/axios";
import KcpCert from "@/components/plugins/kcp/kcp-cert.vue";

import { btn_secondary, btn_icon, USER_TYPES } from "@/assets/variables";
import ClientPage from "@/components/client/templates/client-page.vue";
import PageSection from "@/components/client/templates/page-section.vue";

export default {
    components: {
        ClientPage,
        PageSection,
        KcpCert,
    },
    data: () => ({
        btn_icon,
        btn_secondary,
    }),
    methods: {
        certify() {
            this.$refs.kcpCert.auth();
        },
        async verify(payload) {
            try {
                let { _certification, ci, name, phone, birthday = "" } = payload;
                ci = cryptoAes.encrypt(ci.replace(/[ ]/g, "+"));
                birthday = `${birthday.slice(0, 4)}-${birthday.slice(4, 6)}-${birthday.slice(6, 8)}`;
                const { _user } = await api.v1.users.existence({ ci, type: this.type });

                // 계정 존재
                if (_user) {
                    //소설 통합
                    if (this.accessToken) {
                        if (confirm("이미 가입된 회원입니다. 소설 계정을 통합하시겠습니까?")) {
                            const { accessToken } =
                                (
                                    await axios({
                                        url: "/api/v1/users/integrate",
                                        method: "post",
                                        data: { ci, name, phone, birthday, type: this.type },
                                        headers: { Authorization: `Bearer ${this.accessToken}` },
                                    })
                                )?.data || {};

                            await this.$store.dispatch("login", { accessToken });
                            this.$router.push({ path: "/" });
                        } else {
                            this.$router.push({ path: "/login" });
                        }
                    }
                    //중복 회원
                    else {
                        alert("이미 가입된 회원입니다.");
                    }
                }
                // 회원가입
                else {
                    // 소셜 회원가입
                    if (this.accessToken) {
                        if (this.$route.query.platform === "naver") {
                            alert("회원 정보가 없습니다. 먼저 회원가입을 진행해주세요.");
                            //temp user 삭제
                            await axios({
                                url: "/api/v1/users/deleteTempUser",
                                method: "delete",
                                headers: { Authorization: `Bearer ${this.accessToken}` },
                            });

                            this.$router.push("/join");
                            return;
                        }

                        this.$router.push({ path: "/join/agreement", query: { _certification, type: this.type, accessToken: this.accessToken } });
                        //일반 회원가입
                    } else {
                        this.$router.push({ path: "/join/agreement", query: { _certification, type: this.type } });
                    }
                }
            } catch (error) {
                console.error(error);
                alert(error.response ? error.response.data.message : error.message);
            }
        },
    },
    computed: {
        accessToken() {
            return this.$route.query.accessToken;
        },
        type() {
            return this.$route.query.accessToken ? USER_TYPES.PERSON.value : this.$route.query.type;
        },
    },
};
</script>
