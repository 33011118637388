var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-btn-toggle', {
    attrs: {
      "color": "primary"
    },
    on: {
      "change": _vm.emit
    },
    model: {
      value: _vm.query['issue.event.type'],
      callback: function ($$v) {
        _vm.$set(_vm.query, 'issue.event.type', $$v);
      },
      expression: "query['issue.event.type']"
    }
  }, [_vm._l(_vm.items, function (item) {
    return [_c('v-btn', _vm._b({
      key: item.value
    }, 'v-btn', {
      value: item.value,
      height: 38,
      color: 'white'
    }, false), [_c('span', [_vm._v(" " + _vm._s(item.text) + " ")])])];
  })], 2);

}
var staticRenderFns = []

export { render, staticRenderFns }