<template>
    <list-search v-bind="{ showsSearch }" @search="emit">
        <v-row align="center" class="ma-n2">
            <v-col cols="12" sm="6" md="3" lg="" class="pa-2">
                <v-text-field v-model="query.createdAt[0]" type="date" label="발급일/사용일(시작)" clearable v-bind="attrs_input__console" />
            </v-col>
            <v-col cols="12" sm="6" md="3" lg="" class="pa-2">
                <v-text-field v-model="query.createdAt[1]" type="date" label="발급일/사용일(종료)" clearable v-bind="attrs_input__console" />
            </v-col>
            <v-col cols="12" sm="6" md="3" lg="" class="pa-2">
                <autocomplete-user v-model="query['_user']" label="회원" placeholder="전체" :itemText="({name, phone}) => `${name} (${phone})`" clearable v-bind="attrs_input__console" v-on="{ emit }" />
            </v-col>
            <v-col cols="12" sm="6" md="3" lg="" class="pa-2">
                <v-text-field v-model="query['searchValue']" placeholder="전체" label="검색" clearable v-bind="attrs_input__console" @keydown.enter="emit" />
            </v-col>
        </v-row>
    </list-search>
</template>

<script>
import { attrs_input__console } from "@/assets/variables";

import ListSearch from "@/components/console/dumb/list-search.vue";
import AutocompleteUser from "@/components/dumb/autocomplete-user.vue";
import AutocompleteShopPoint from "@/components/dumb/autocomplete-shop-point.vue";

let initQuery = (query = {}) => ({
    ...query,

    ["createdAt"]: query?.["createdAt"] || [null, null],

    ["_user"]: query?.["_user"] ?? null,
    ["searchValue"]: query?.["searchValue"] ?? null,
});

export default {
    components: {
        ListSearch,
        AutocompleteUser,
        AutocompleteShopPoint,
    },
    props: {
        showsSearch: { type: Boolean, default: false },
    },
    data: () => ({
        query: initQuery(),

        attrs_input__console,
    }),
    mounted() {
        this.sync();
    },

    methods: {
        sync() {
            this.query = initQuery(this.$route.query);
        },
        emit() {
            const { ...query } = this.query || {};

            if (!query["createdAt"]) delete query["createdAt"];
            if (!query["createdAt"]?.[0] && !query?.createdAt?.[1]) delete query["createdAt"];

            if (!query["_user"]) delete query["_user"];
            if (!query["searchValue"]) delete query["searchValue"];

            delete query.page;

            this.$router.push({ query });
        },
    },
};
</script>

<style></style>
