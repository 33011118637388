import { render, staticRenderFns } from "./shop-point-issue-event-type.vue?vue&type=template&id=bb3dceb0&"
import script from "./shop-point-issue-event-type.vue?vue&type=script&lang=js&"
export * from "./shop-point-issue-event-type.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports