var render = function render(){
  var _vm$user, _vm$user2, _vm$user2$point, _vm$user2$point$forma, _ref, _ref$format, _vm$user3, _vm$user3$meta, _vm$user3$meta$wishli, _vm$user3$meta$wishli2;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "mb-54px mb-md-64px mb-lg-80px"
  }, [_c('v-row', {
    staticClass: "row--sm"
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "lg": ""
    }
  }, [_c('div', {
    staticClass: "font-size-18 font-size-md-22 font-size-lg-32 font-weight-light"
  }, [_c('b', {
    staticClass: "font-weight-bold"
  }, [_vm._v(_vm._s((_vm$user = _vm.user) === null || _vm$user === void 0 ? void 0 : _vm$user.name))]), _vm._v("님은"), _c('br'), _vm._v(_vm._s(_vm.levelName) + "등급입니다. ")])]), _c('v-col', {
    attrs: {
      "cols": "12",
      "lg": "auto"
    }
  }, [_c('v-row', {
    attrs: {
      "no-gutters": ""
    }
  }, [_c('v-col', {
    staticClass: "ml-n1px d-md-none",
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-divider', {
    attrs: {
      "vertical": ""
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "3",
      "lg": ""
    }
  }, [_c('div', {
    staticClass: "py-8px",
    style: _vm.$vuetify.breakpoint.lgAndUp ? 'min-width: 144px' : ''
  }, [_c('div', {
    staticClass: "text-center"
  }, [_c('div', {
    staticClass: "txt txt--sm"
  }, [_vm._v("마일리지")]), _c('div', {
    staticClass: "font-size-18 font-size-lg-24 primary--text mt-18px mt-md-20px"
  }, [_vm._v(_vm._s((_vm$user2 = _vm.user) === null || _vm$user2 === void 0 ? void 0 : (_vm$user2$point = _vm$user2.point) === null || _vm$user2$point === void 0 ? void 0 : (_vm$user2$point$forma = _vm$user2$point.format) === null || _vm$user2$point$forma === void 0 ? void 0 : _vm$user2$point$forma.call(_vm$user2$point)) + "M")])])])]), _c('v-col', {
    staticClass: "ml-n1px",
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-divider', {
    attrs: {
      "vertical": ""
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "3",
      "lg": ""
    }
  }, [_c('div', {
    staticClass: "py-8px",
    style: _vm.$vuetify.breakpoint.lgAndUp ? 'min-width: 144px' : ''
  }, [_c('div', {
    staticClass: "text-center"
  }, [_c('div', {
    staticClass: "txt txt--sm"
  }, [_vm._v("쿠폰")]), _c('div', {
    staticClass: "font-size-18 font-size-lg-24 primary--text mt-18px mt-md-20px"
  }, [_vm._v(_vm._s((_ref = _vm.couponsSummary.totalCount || 0) === null || _ref === void 0 ? void 0 : (_ref$format = _ref.format) === null || _ref$format === void 0 ? void 0 : _ref$format.call(_ref)) + " 장")])])])]), _c('v-col', {
    staticClass: "ml-n1px",
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-divider', {
    attrs: {
      "vertical": ""
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "3",
      "lg": ""
    }
  }, [_c('div', {
    staticClass: "py-8px",
    style: _vm.$vuetify.breakpoint.lgAndUp ? 'min-width: 144px' : ''
  }, [_c('div', {
    staticClass: "text-center"
  }, [_c('div', {
    staticClass: "txt txt--sm"
  }, [_vm._v("관심상품")]), _c('div', {
    staticClass: "font-size-18 font-size-lg-24 primary--text mt-18px mt-md-20px"
  }, [_vm._v(_vm._s((_vm$user3 = _vm.user) === null || _vm$user3 === void 0 ? void 0 : (_vm$user3$meta = _vm$user3.meta) === null || _vm$user3$meta === void 0 ? void 0 : (_vm$user3$meta$wishli = _vm$user3$meta.wishlistCount) === null || _vm$user3$meta$wishli === void 0 ? void 0 : (_vm$user3$meta$wishli2 = _vm$user3$meta$wishli.format) === null || _vm$user3$meta$wishli2 === void 0 ? void 0 : _vm$user3$meta$wishli2.call(_vm$user3$meta$wishli)) + "개")])])])]), _c('v-col', {
    staticClass: "ml-n1px d-md-none",
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-divider', {
    attrs: {
      "vertical": ""
    }
  })], 1)], 1)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }